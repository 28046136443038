import React from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';

import Left from '../../assets/images/about/stablecoin/left.png'
import RightUp from '../../assets/images/about/stablecoin/rightup.png'
import RightMiddle from '../../assets/images/about/stablecoin/rightmiddle.png'
import RightDown from '../../assets/images/about/stablecoin/rightdown.png'

export const StableCoin = () => {
    return (
        <div className={clsx(styles.coin, "row about-content")}>
            <div className="col-lg-6 pr-md-5 my-5">
                <div className="about-h-img" data-aos="fade-down-left">
                    <img src={Left} width="100%" alt="" />
                </div>

                <div className={styles.leftText}>
                    <div
                        className={clsx(styles.leftHeading, "justify-content-center flex-column flex-sm-row justify-content-sm-between")}
                    >
                        <h3 data-aos="fade-down-left">Hybrid Stable Coin</h3>
                        <a href="" className="btn-unfill" data-aos="fade-down-right">Buy Tokens <i className="fa fa-angle-right"></i></a>
                    </div>
                    <p data-aos="fade-down-left">USDFX is a new hybrid stablecoin, backed by a multiset of assets including fiat currencies provided by reputable investors (Institutional, Royal Family Offices, VC Funds), company profit generated through the merchant payment system fees provided by OmaraPay while remittance fees, centralized exchange fees and a liquidity pool of multiple crypto assets provided by OmaraSwap.</p>

                    <div
                        className={clsx(styles.leftHeading, "justify-content-center flex-column flex-sm-row justify-content-sm-between")}
                    >
                    
                        <a href="" className="btn-unfill" data-aos="fade-up-right">Download Our Presentation</a>
                        <a href="" className="btn-unfill" data-aos="fade-down-left">Be Our Partner Marchant </a>
                    </div>

                </div>

            </div>
            

            <div  className="col-lg-6">
                <div className="row my-4">
                    <div className="col-sm-6">
                        <div className={styles.aboutText}>
                            <h4 data-aos="fade-down-left">Collateralized loan against commodities</h4>
                            <p data-aos="fade-down-right">USDFX provides a collateralized loan against commodities, assets, or revenue generated by the company to support the recapitalization for that particular Enterprise’s existing business operations in compliance to the laws set by the Central Bank of UAE.</p>
                        </div>
                    </div>

                    <div className="col-sm-6" data-aos="fade-up">
                        <img src={RightUp} width="100%" alt="" />
                    </div>

                </div>

                <div className="row my-4">
                    <div className="col-sm-6 about-text">
                        <div className={styles.aboutText}>
                            <h4 data-aos="fade-down">Medium of currency for B2B payments</h4>
                            <p data-aos="fade-down-left">USDFX acts as a medium of currency to facilitate the payments between merchants and suppliers providing round the clock convenience while saving time and cost. </p>
                        </div>
                    </div>

                    <div className="col-sm-6" data-aos="fade-down">
                        <img src={RightMiddle} width="100%" alt="" />
                    </div>

                </div>

                <div className="row my-4">
                    <div className="col-sm-6 about-text">
                        <div className={styles.aboutText}>
                            <h4 data-aos="fade-down-left">Fully Transparent and Audited</h4>
                            <p data-aos="fade-up-right">USDFX reserve is managed by an investment company that acts as a private management of profit and a custodian, who is audited on a periodic basis to maintain adherence and compliance. </p>
                        </div>
                    </div>

                    <div className="col-sm-6" data-aos="fade-up">
                        <img src={RightDown} width="100%" alt="" />
                    </div>

                </div>

            </div>

        </div>
    );
};
